export default {
    COUPANG_PARTNERS: 'Coupang Partners',
    LANDING_SLOGAN: 'Make profit as{br}Rocket Partners',
    LANDING_WHY_AFFILIATE: 'Why Coupang Affiliate?',
    LANDING_WHY1_TITLE: 'Easy signup',
    LANDING_WHY2_TITLE: 'Various advertisements',
    LANDING_WHY3_TITLE: 'High return',
    LANDING_WHY1_DESCRIPTION: 'Anyone who is a Coupang member can easily sign up. Sign up right now and get reports on your performance and convenient settlement!',
    LANDING_WHY2_DESCRIPTION: 'You can advertise millions of items for sale at Coupang. Choose your products and create your own ad just in one click.',
    LANDING_WHY3_DESCRIPTION: 'Coupang affiliates can expect more profit with high commission.',
    LANDING_HOME_BANNERS: 'Current promotions',
    LANDING_HOW_TO_USE: 'How to use it?',
    LANDING_HOW1_DESCRIPTION: 'Show Coupang affiliate ads on your site',
    LANDING_HOW2_DESCRIPTION: 'Visitors click on your ad and make a purchase at Coupang',
    LANDING_HOW3_DESCRIPTION: 'You get paid N% of the value purchased by the visitor',

    MEMBER_AFFILIATE_TITLE: "You haven't joined as Coupang partener yet",
    MEMBER_AFFILIATE_REGISTER_TIP: 'Do you want to proceed with partener signup?',
    MEMBER_AFFILIATE_JOIN_NOW: 'Join now',

    MEMBER_BLOCKED_TITLE: 'This account is restricted from signing up according to terms and policies',
    MEMBER_BLOCKED_TIP: 'Please contact Coupang Partners for details.',

    GO_BACK: 'Go back',

    GOTO_LOGIN_TITLE: 'You must be a Coupang member {newLine} to sign up as an affiliate.',
    GOTO_LOGIN_TIP: 'You will be redirected to Coupang login page automatically in {seconds} seconds.',

    REGISTRATION_TC_V1: '[Required]Terms of Service',
    REGISTRATION_PS_V1: '[Required]Collection and use of personal information',
    REGISTRATION_PRIVACY_POLICY: 'Privacy Policy',
    REGISTRATION_TC_PI_V1: 'I understand and agree to the collection of personal information.',
    REGISTRATION_TITLE: 'Affiliate Signup',
    REGISTRATION_TC_TITLE: 'Terms and Conditions Agreement',
    REGISTRATION_TC_UNDERSTAND_TC: 'I understand and agree to the Terms of Service.',
    REGISTRATION_TC_UNDERSTAND_PI: 'I understand and agree to the collection of personal information.',
    REGISTRATION_TC_DETAIL_TITLE: 'Terms of Service',
    REGISTRATION_TC_PLEASE_ACCEPT: 'Please agree to the T&C',
    REGISTRATION_PROFILE_TITLE: 'Enter profile information',
    REGISTRATION_PROFILE_TIP1: "Please enter your blog, website or mobile app information to display Coupang's link or banner.",
    REGISTRATION_PROFILE_TIP2: 'At least one website/mobile must be entered.',
    REGISTRATION_PROFILE_TIP: `Please enter <span>all</span> your blog, website or mobile app information to show Kupang's link or banner.If you are advertising on an unregistered channel, you may be considered fraudulent, so do not be disadvantaged. (Additional channel registration is available in the 'Account Management' menu even after you sign up.)`,
    REGISTRATION_PROFILE_AF_SELECT: 'Please select a business type',
    REGISTRATION_PROFILE_AF_SELECT_TIPS: 'In the case of simplified taxpayers, it is impossible to issue tax invoices, so please select the type of sole proprietor (tax invoice N).',
    REGISTRATION_PROFILE_AF_INDIVIDUAL: 'Individual',
    REGISTRATION_PROFILE_AF_COMPANY: 'Corporation',
    REGISTRATION_PROFILE_AF_OTHER_INDUSTRIES: 'Individual Carrier (Other industries)',
    REGISTRATION_PROFILE_AF_TOOLTIP: 'If you are an individual business operator, please select one based on the items listed on your business registration card.',
    REGISTRATION_PROFILE_ENTER_ONLY_ALPHANUMERIC: 'Please enter alphanumeric characters only',
    REGISTRATION_PROFILE_WEBSITES: 'List of websites',
    REGISTRATION_PROFILE_APPS: 'List of mobile apps',
    REGISTRATION_PROFILE_APPS_PLACEHOLDER: 'Please enter the App URL of the Play Store/Apple App Store',
    REGISTRATION_PROFILE_APPS_SHORT_PLACEHOLDER: 'App URL of the Play Store/Apple App Store',
    REGISTRATION_PROFILE_APP_URL: 'App url',
    REGISTRATION_PROFILE_WEBSITE_APP_URL: 'Website/Mobile app url',
    REGISTRATION_PROFILE_WEBSITE_APP_URL_PLACEHOLDER_ID: 'Enter ID',
    REGISTRATION_PROFILE_WEBSITE_APP_URL_PLACEHOLDER_ADDRESS: 'Enter address',
    REGISTRATION_PROFILE_URL_TOOLTIP: "When entering the ID, the entire URL will be completed automatically. If that's not the url you want, please enter the URL directly.",
    REGISTRATION_PROFILE_URL_QUANTITY_TOOLTIP: 'Please input the number of Daily Visitor/Follower/Download',
    REGISTRATION_PROFILE_URL_QUANTITY_PLACEHOLDER: 'Quantity',
    REGISTRATION_PROFILE_DESCRIPTION: 'What is the nature of your website(s)?',
    REGISTRATION_PROFILE_DESCRIPTION_PLACEHOLDER: 'Ex) Childcare information site, IT digital blog...',
    REGISTRATION_PROFILE_TOPICS: 'What topics describe your website or mobile app?',
    REGISTRATION_PROFILE_TOPICS_PLACEHOLDER: 'Direct input',
    REGISTRATION_PROFILE_TOPICS_LIGHT: 'Topics',
    REGISTRATION_PROFILE_SIGNUP: 'Signup complete',
    REGISTRATION_PROFILE_MSG_INVALID_SITE: 'Please enter a valid website URL',
    REGISTRATION_PROFILE_MSG_INVALID_APP: 'Please enter valid app URL',
    REGISTRATION_PROFILE_MSG_ENTER_WEBSITE_OR_APP: 'You must enter at least one website or one mobile app',
    REGISTRATION_PROFILE_MSG_SELECT_AT_LEAST_ONE_TOPIC: 'Please select/enter at least one topic',
    REGISTRATION_PROFILE_MSG_MODIFY_SITE_APP: 'I confirm that the site is not in violation of the "Coupons Partners Terms and Conditions"',
    REGISTRATION_PROFILE_AGREE_ALL: 'I agree to all.',
    REGISTRATION_PROFILE_OWNER: 'Owner',
    REGISTRATION_PROFILE_OWNER_TOOLTIP: 'Are you the owner of this website?',
    REGISTRATION_SUMMARY_WELCOME: 'Hi {name}, welcome!',
    REGISTRATION_SUMMARY_THANKS: 'Thank you for applying for Coupang Affiliate!',
    REGISTRATION_SUMMARY_3_DAYS: 'The approval will take 3 business days.',
    REGISTRATION_SUMMARY_NOTICE: "If approved, we'll contact you via your email{newLine}{email}.",
    REGISTRATION_AFFILIATE_IN_PROGRESS_TITLE: 'Approval request is under review',
    REGISTRATION_AFFILIATE_IN_PROGRESS_DETAIL: 'The approval process is currently in progress. Please wait a little longer.{newLine}Thank you.',
    REGISTRATION_AFFILIATE_REJECTED_TITLE: 'Rejection of final approval',
    REGISTRATION_AFFILIATE_REJECTED_REFUSAL_FOLLOWS: 'Final authorization of this account has been rejected. Please check and correct the reason for rejection below and reapply.',
    REGISTRATION_AFFILIATE_REJECTED_DETAIL_P1: 'Your affiliate subscription request has been declined.',
    REGISTRATION_AFFILIATE_REJECTED_DETAIL_P2: 'All the information you entered will be deleted. For more information, please contact the Coupang CS Center.',
    ACCEPT_TC_TO_CONTINUE: 'You must agree to the collection of information to use the service.',

    REGISTRATION_AFFILIATE_REAPPLY: 'Reapply for final approval',
    REGISTRATION_AFFILIATE_REAPPLY_COMPLETED: 'Your request for final approval review has been completed!',
    REGISTRATION_AFFILIATE_REAPPLY_COMPLETED_NOTICE: 'After review and approval is completed, we will contact you to the registered email {email}',
    REGISTRATION_AFFILIATE_REAPPLY_UNDER_REVIEW: 'The final approval review request has been completed and is under review. After approval is completed, we will contact you to the registered email {email}',
    REGISTRATION_FOREIGNER_USER_CANNOT_JOIN: 'Foreigners cannot sign up for Partners.',
    REGISTRATION_UNDERAGE_USER_CANNOT_JOIN: 'Minors cannot sign up for Partners.',
    REGISTRATION_IDENTITY_CONFIRM: 'Please confirm it is your identity. If not, you may not be able to earn commission.',
    REGISTRATION_PLEASE_CONFIRM_IT_IS_YOUR_IDENTITY: 'Please confirm it is your identity.',
    REGISTRATION_WITHOUT_REAL_NAME_AUTHENTICATION: 'without real-name va{link}',
    REGISTRATION_REAL_NAME_AUTHENTICATION_TIPS_1: 'This is confirmed through the member\'s Coupang identity verification.',
    REGISTRATION_REAL_NAME_AUTHENTICATION_TIPS_2: 'Please check and confirm that your information is correct.',
    REGISTRATION_GOTO_REAL_NAME_AUTHENTICATION: 'go to authenticate',
    REGISTRATION_RRN: 'RRN collection',
    REGISTRATION_BANK: 'Bank info collection',
    REGISTRATION_MUST_AGREE: 'Required',
    REGISTRATION_DETAIL_FOR_PRIVACY: 'For more information, you may visit {link}. ',
    REGISTRATION_PII_COLLECTION: 'Payment information collection agreement',
    REGISTRATION_PII_COLLECTION_CONTENT: 'Coupang collects your PII including bank account information to…',
    REGISTRATION_PII_COLLECTION_LATER_TIPS: 'The payment information field is activated only for members who meet the payment conditions. {br} (Payment conditions: when final approval has been completed and the minimum payment standard is 10,000 won or more)',
    REGISTRATION_CHANNEL_TIPS: 'Advertising on unregistered channels may be considered fraudulent, and we hope that you will not suffer any disadvantages as a result. After signing up, you can add blog and site addresses and app lists in Account Management on My Page.',
    REGISTRATION_COMMISSION_CAP_TIPS: 'Maximum amount for monthly commission is 30M KRW per month, and any exceeding amount is not paid nor carried over.',
    
    MARKETING_PI_COLLECTION: '[Optional] Consent to collection and use of personal information for marketing purposes',
    MARKETING_EMAIL: '[Optional] Consent to receive advertising information (email)',
    MARKETING_PI_COLLECTION_OPT_IN: 'Agreed to PI collection for marketing purpose',
    MARKETING_PI_COLLECTION_OPT_OUT: 'Disagree to PI collection for marketing purpose',
    MARKETING_EMAIL_OPT_IN: 'Agreed to external notification (email)',
    MARKETING_EMAIL_OPT_OUT: 'Disagree to external notification (email)',

    REFERRER_LABEL: 'Referral Code',
    REFERRER_TOOLTIP: "Please enter the referee's Kupang Partners ID. ID is AF + 7 digits (for example, AF1234567)",
    REFERRER_VAL_MSG: 'ID is AF + 7 digits (for example, AF1234567)',
    REFERRER_PLACEHOLDER: 'Consists of AF + 7 digits.',
    REFERRER_NOT_FOUND: 'Affiliate not found',
    REFERRER_NOT_FINALLY_APPROVED: 'Affiliate not finally approved',
    REFERRER_DEACTIVATION_IN_PROGRESS: 'Affiliate deactivation in progress',
    REFERRER_DEACTIVATION_COMPLETE: 'Affiliate deactivation complete',
    REFERRER_FRAUD: 'Affiliate is fraud',

    TRACKING_CODE: 'Affiliate ID',

    PAYMENT_ALERT_GREETING: 'Hi {name}',
    PAYMENT_ALERT_TITLE_APPROVED: ',your subscription has been approved!',
    PAYMENT_ALERT_TITLE_AUTO_APPROVED: 'your registration is completed',
    PAYMENT_ALERT_AFFILIATE_ID_IS: '{name}, your Affiliate ID is {affiliateCode}.',
    PAYMENT_ALERT_MESSAGE: 'You need to enter your billing information to have your fees paid.{newLine}Do you want to enter it now?',
    PAYMENT_ALERT_LATER: 'Later',
    PAYMENT_ALERT_NOW: 'Enter payment information',
    APPROVED_AFFILIATE_ID_IS: '{name}, your Affiliate ID is {affiliateCode}.',
    APPROVED_CREATE_A_LINK_NOW: 'Create a link right now.',
    PAYMENT_TITLE: 'Enter payment information',
    PAYMENT_SELECT_TYPE_TITLE: 'Please select a business type',
    PAYMENT_SELECT_TYPE_INDIVIDUAL: 'Individual',
    PAYMENT_SELECT_TYPE_INDIVIDUAL_OTHERS: 'All other industries',
    PAYMENT_SELECT_TYPE_CORPORATION: 'Corporation',
    PAYMENT_SELECT_TYPE_PLEASE_SELECT_A_TYPE: 'Please select your business type',
    PAYMENT_SECTION_ACQUISITION_OF_PI: 'Consent to acquisition of personal information',
    PAYMENT_SECTION_PAYER_INFORMATION: 'Payee Information',
    PAYMENT_SECTION_COMPANY_INFORMATION: 'Company Information',
    PAYMENT_SECTION_CONTACT: 'Contact Information',
    PAYMENT_SECTION_REFERRER: 'If you have a referral code, please enter it.',
    PAYMENT_SECTION_ACCOUNT_INFORMATION: 'Account Information',
    PAYMENT_RRN: 'Resident Registration Number',
    PAYMENT_RRN_TIP1: 'In order to <span class="color-accent ft-bold-500">pay the commission</span>, the <span class="color-accent ft-bold-500">resident registration number</span> is collected and used in accordance with Article 145 of the Income Tax Act, and it is <span class="color-accent ft-bold-500">kept for 5 years</span> under this Act.​',
    PAYMENT_RRN_TIP2: 'Your RRN should be matched with your name and date of birth provided above',
    PAYMENT_BRN: 'Business Registration Number',
    PAYMENT_PHONE_NUMBER: 'Phone number',
    PAYMENT_EMAIL: 'Email',
    PAYMENT_EMAIL_PLACEHOLDER: 'Ex) email@domain.com',
    PAYMENT_OMIT_MINUS_SYMBOL: "Please omit '-'",
    PAYMENT_BANK: 'Bank',
    PAYMENT_SELECT_A_BANK: 'Please select a bank',
    PAYMENT_ACCOUNT_NUMBER: 'Account number',
    PAYMENT_ACCOUNT_HOLDER: 'Account holder',
    PAYMENT_COMPANY_REPRESENTATIVE_NAME: 'Representative name',
    PAYMENT_COMPANY_NAME: 'Company name',
    PAYMENT_COMPANY_NAME_TIP: 'Business/corporation name (as specified in the business registration certificate)',
    PAYMENT_ADDRESS: 'Company Address',
    PAYMENT_ADDRESS_TIP: 'Please input company address not personal address',
    PAYMENT_COMPANY_BUSINESS_SECTOR: 'Business sector',
    PAYMENT_COMPANY_BUSINESS_TYPE: 'Business type',
    PAYMENT_COMPANY_SUB_BUSINESS_TYPE: 'Sub type',
    PAYMENT_CONSENT_DETAIL_TITLE: 'Agreement to collection and use of personal information',
    PAYMENT_UPLOAD_DOCUMENT: 'Upload settlement documents',
    PAYMENT_MSG_BRN_IS_10_DIGITALS: 'BRN is a 10-digital number',
    PAYMENT_MSG_CHECK_BRN_FIRST: 'Please check you BRN first through the check button',
    PAYMENT_MSG_INVALID_BRN: 'Invalid BRN, please double check',
    PAYMENT_MSG_ENTER_NAME_FIRST_FOR_RRN_VERIFICATION: 'Please enter name first for RRN verification',
    PAYMENT_MSG_RRN_IS_13_DIGITALS: 'RRN is a 13-digital number',
    PAYMENT_MSG_RRN_ILLEGAL: 'Name and resident number do not match',
    PAYMENT_MSG_CHECK_RRN_FIRST: 'Please check you RRN first through the check button',
    PAYMENT_MSG_INVALID_BANK_ACCOUNT: 'Invalid bank account, please check your bank and account number',
    PAYMENT_MSG_VERIFY_BANK_ACCOUNT_FIRST: 'Please verify your bank account first through the verify button',
    PAYMENT_MSG_SELECT_BANK_FIRST: 'Please select the Bank first',

    HEADER_HOME: 'Home',
    HEADER_PRODUCT_LINK: 'Create links',
    HEADER_REPORT: 'Report',
    HEADER_PERFORMANCE_REPORT: 'Performance Report',
    HEADER_REPORT_TREND: 'Performance Trend Report',
    HEADER_REPORT_TREND_SUB_TITLE: 'All-day data is reflected around 4:00 PM.',
    HEADER_REPORT_DYNAMIC_WIDGET: 'Performance Dynamic Widget Report',
    HEADER_REPORT_PERFORMANCE_INSIGHT: 'Performance Insight Report',
    HEADER_EARNING_REPORT: 'Earning Report',
    HEADER_REPORT_EARNING: 'Earning Summary',
    HEADER_REPORT_SETTLEMENT: 'Settlement Report',
    HEADER_REPORT_SETTLEMENT_DETAILS: 'Settlement Details',
    HEADER_REPORT_PAYOUT: 'Payout Report',
    HEADER_REPORT_PAYOUT_DETAILS: 'Payout Details',
    HEADER_REPORT_DOWNLOAD_REPORT: 'Download Report',
    HEADER_REPORT_REFERRAL: 'Referral Report',
    HEADER_EDIT_PROFILE: 'Edit Profile Information',
    HEADER_ENTER_PAYMENT: 'Input Payment & Tax Information',
    HEADER_EDIT_PAYMENT: 'Edit Payment & Tax Information',
    HEADER_SUB_ID: 'Sub Id Management',
    OPT_IN_OUT_SETTINGS: 'Notification opt-in/out settings',
    HEADER_UNSUBSCRIBE: 'Unsubscribe',
    HEADER_PL_LINK: 'Product Link',
    HEADER_PL_BANNER: 'Banner',
    VIDEO_BANNER: 'Video Banner',
    VIDEO_BANNER_GENERATION: 'Create Video Banner',
    HEADER_PL_SEARCH_BAR: 'Search Bar',
    HEADER_PL_DYNAMIC_BANNER: 'Dynamic Banner',
    HEADER_PL_EVENT_LIST: 'Event / Promotion Links',
    EVENT_LIST_TITLE: 'Event / Promotion List',
    EVENT_TITLE: 'Event / Promotion',
    EVENT_PERIOD: 'Event Period',
    HEADER_SETTLEMENT: 'Settlement',
    HEADER_SETTLEMENT_UPLOAD: 'Upload settlement document',
    HEADER_SETTLEMENT_TAXINVOICE: 'View tax invoice',
    HEADER_TOOLS: 'Tools',
    HEADER_TOOLS_OPEN_API: 'Partners API',
    HEADER_TOOLS_CHROME_EXTENSION: 'Chrome Extension',
    HEADER_HELP: 'Help',
    HEADER_ANNOUNCEMENTS: 'Announcements',
    HEADER_TP: 'Terms and policies',
    HEADER_HELP_TERMS: 'Terms',
    HEADER_HELP_OPERATING_POLICY: 'Operating Policy',
    HEADER_HELP_PRIVACY_POLICY: 'Privacy Policy',
    HEADER_HELP_USAGE_GUIDE: 'Usage Guide',
    HEADER_HELP_PARTNERS_GUIDE: 'Partners Guide',
    HEADER_HELP_USAGE_GUIDE_TAG: 'Tag usage guide',
    HEADER_HELP_OPEN_API: 'Open API',
    HEADER_HELP_OPEN_API_GUIDE: 'Guide',
    HEADER_HELP_OPEN_API_DOC: 'Document',
    HEADER_HELP_FAQ: 'FAQ',
    HEADER_HELP_VOC: 'VOC',
    HEADER_HELP_VOC_CREATE: 'VOC Create',
    HEADER_HELP_VOC_HISTORY: 'VOC History',
    HEADER_HELP_VOC_CREATE_SUBTITLE: 'Please let us know any inconveniences or wishes you feel while using Coupang Partners.',
    HEADER_HELP_VOC_HISTORY_SUBTITLE: 'You can check the history of previous inquiries.',
    HEADER_HELP_VOC_HISTORY_MY_VOC: 'My VOCs',
    VOC_CATEGORY_PLACEHOLDER: 'Select Category',
    VOC_CATEGORY_VALIDATION_MESSAGE: 'Category is required',
    VOC_TITLE_PLACEHOLDER: 'Input Title',
    VOC_TITLE_VALIDATION_MESSAGE: 'Title is required',
    VOC_CONTENT_PLACEHOLDER: 'Input Content',
    VOC_CONTENT_VALIDATION_MESSAGE: 'Content is required',
    VOC_CREATE_ONE: '문의하기',

    MY_ACCOUNT_PROFILE: 'Profile',
    MY_ACCOUNT_PROFILE_SUBTITLE: 'Affiliate ID, website, app, site type, keyword change',
    MY_ACCOUNT_PAYMENT: 'Payment & Tax',
    MY_ACCOUNT_PAYMENT_SUBTITLE: 'Edit contact and account number',
    MY_ACCOUNT_UNSUBSCRIBE: 'Unsubscribe',
    MY_ACCOUNT_UNSUBSCRIBE_SUBTITLE: 'Disable affiliate account',
    MY_ACCOUNT_REACTIVATE: 'Re-join',
    MY_ACCOUNT_REACTIVATE_SUBTITLE: 'You can apply for re-join from 31 days after your unsubscribe',
    MY_ACCOUNT_SUB_ID_MANAGEMENT: 'Sub Id Management',
    MY_ACCOUNT_SUB_ID_MANAGEMENT_SUBTITLE: 'Create and delete channel ID',

    EDIT_PROFILE_TITLE: 'Edit my profile',
    EDIT_PAYMENT_TITLE: 'Edit Payment & Tax',
    CHANGE_AFFILIATE_TYPE_TITLE: 'Change Business Type',
    CHANGE_AFFILIATE_TYPE_INDIVIDUAL_TO_OTHERS: 'Individual -> Other Industries',
    CHANGE_AFFILIATE_TYPE_OTHERS_TO_INDIVIDUAL: 'Other Industries -> Individual',
    CHANGE_AFFILIATE_TYPE_ALERT: 'You can change your business type to another type by clicking <a href="/#affiliate/change-business-type">here</a>.',

    CIM_ALERT_TITLE: 'You can modify your business information by following the procedure below.',
    CIM_ALERT_STEP1: 'Please send an image of your business registration certificate or a copy of your bankbook with the changed business information reflected to Partners. (Help > Contact Us > Contact Us)',
    CIM_ALERT_STEP2: 'The information will be changed once the review is completed by the contact person.',

    AFFILIATE_HOME: 'Home',
    PRODUCT_LINK: 'Product link',
    PRODUCT_LINK_STEP1_SLOGAN: 'Find your favorite products',
    PRODUCT_LINK_STEP2_SLOGAN: 'You can create a link or banner to advertise products. Click {createLinkButton} and create advertising links!',
    PRODUCT_LINK_STEP2_SUB_SLOGAN: 'HTML code in the form of a banner is provided with a shortened URL or product image that is linked to the product page.',
    PRODUCT_LINK_STEP1: 'Product search',
    PRODUCT_LINK_STEP2: 'Choose a product',
    PRODUCT_LINK_STEP3: 'Create a URL or banner',
    PRODUCT_LINK_BEST_PRODUCTS: 'Best products',
    PRODUCT_LINK_GOTO: 'View product detail',
    PRODUCT_LINK_GENERATION: 'Create a link',
    PRODUCT_LINK_SLOGAN: 'Please copy the shortened URL or HTML code and post it on the website.',
    PRODUCT_LINK_SEARCH_PLACEHOLDER: 'Search for the item you are looking for!',
    PRODUCT_LINK_NO_RESULT: 'No results were found for your search.',
    LINK_GENERATION_SHORTEN_URL: 'Shortened URL',
    LINK_GENERATION_IMAGE_TEXT: 'Image + Text',
    LINK_GENERATION_PREVIEW: 'Preview',
    LINK_GENERATION_BORDERLINE: 'Borderline',
    LINK_GENERATION_COPY_URL: 'Copy URL',
    LINK_GENERATION_COPY_HTML: 'Copy HTML',
    LINK_GENERATION_BORDER: 'Border?',
    LINK_GENERATION_CODE_TIP: 'Use tags for your blog where iframes do not apply',
    LINK_GENERATION_CODE_TYPE_IFRAME: 'General tag',
    LINK_GENERATION_CODE_TYPE_IMAGE: 'Blog tag',
    BEST_ROCKET_PRODUCTS: 'Best Rocket Delivery products',
    TOP_PRODUCTS_GOLDBOX: 'Goldbox',
    TOP_PRODUCTS_FASHION: 'Fashion',
    TOP_PRODUCTS_ROCKET_FRESH: 'Rocket Fresh',
    TOP_PRODUCTS_RETAIL_CLEARANCE: 'Retail Clearance',
    TOP_PRODUCTS_MOST_CONVERSION: 'Most Conversion',
    TOP_PRODUCTS_MOST_DISCOUNTED: 'Most Discounted',
    TOP_PRODUCTS_MOST_SOLD: 'Most Sold',
    PRODUCT_SEARCH_SHARE_SEARCH_RESULTS: 'Would you like to share your search results for "{keyword}"?',

    LINK_TO_ANY_PAGE: 'Link to any page',
    LINK_TO_ANY_PAGE_CREATE_LINK: 'Generate',
    LINK_TO_ANY_PAGE_RECOMMENDED_LINKS: 'Recommended links',
    LINK_TO_ANY_PAGE_INVALID_URL: 'Invalid Coupang URL',
    LINK_TO_ANY_PAGE_NOT_SUPPORTED_URL: 'Sorry. This URL is an unsupported form. Only product pages, search results, and promotion pages can be created with partners links.',
    LINK_TO_ANY_PAGE_NOT_SUPPORTED_PRODUCT: 'Sorry, this product cannot be generated as Partners link.',

    CODE_PREVIEW: 'Preview',

    PRODUCT_BANNER: 'Banner',
    PRODUCT_BANNER_SUBTITLE: 'A banner that is linked to a Coupang category is created',
    PRODUCT_BANNER_GENERATION: 'Create banner',
    PRODUCT_BANNER_SELECT_CATEGORY: 'Select category',

    PRESS_META_C_TO_COPY: 'Press #{key}, Enter to copy',

    CATEGORY_ROCKET_DELIVERY: 'Rocket Delivery',
    CATEGORY_COUPANG: 'Coupang',
    CATEGORY_ROCKET_WOW: 'Rocket WOW',
    CATEGORY_SNS: 'SNS',
    CATEGORY_JIKGU: 'Rocket Jikgu',
    CATEGORY_WOMEN_FASHION: 'Fashion-Women',
    CATEGORY_MEN_FASHION: 'Fashion-Men',
    CATEGORY_BEAUTY: 'Beauty',
    CATEGORY_BABY: 'Maternity/Baby',
    CATEGORY_FOOD: 'Food',
    CATEGORY_KITCHEN: 'Kitchen',
    CATEGORY_LIVING: 'Living',
    CATEGORY_HOME_INTERIOR: 'Home/Interior',
    CATEGORY_ELECTRONIC: 'Consumer Electronics',
    CATEGORY_SPORTS: 'Sports/Leisure',
    CATEGORY_CAR: 'Car Supplies',
    CATEGORY_BOOKS_CD_DVD: 'Book/CD-DVD',
    CATEGORY_TOY: 'Toy/Hobby',
    CATEGORY_OFFICE: 'Office/Stationery',
    CATEGORY_PET: 'Pet Supplies',
    CATEGORY_HEALTH: 'Health/Fitness Products',
    CATEGORY_OVERSEA_TRIP: 'Overseas Travel',
    CATEGORY_DOMESTIC_TRIP: 'Domestic Travel',
    CATEGORY_TICKET: 'Performance/Exhibition/Activity',

    UPLOAD_A_FILE: 'Upload a file',
    UPLOAD_BUSINESS_DOC: 'A copy of business registration certificate',
    UPLOAD_BANK_BOOK: 'A copy of bankbook',
    UPLOAD_DOCUMENTS_TIPS: 'The file to be uploaded must be in jpg, jpeg, png format of 5MB or less. Uploading of other types of files may cause errors.',

    DASHBOARD_SEARCH_PRODUCTS: 'Search for product',
    DASHBOARD_DAILY_TREND: 'Daily Trend',
    DASHBOARD_AGGREGATE_MONTH: 'Monthly Aggregation',
    DASHBOARD_VIEW_FULL_REPORT: 'View full report',
    DASHBOARD_AGG_CLICK: 'Click',
    DASHBOARD_AGG_PURCHASE_AMOUNT: 'Purchase amount',
    DASHBOARD_AGG_REVENUE: 'Revenue',
    EARNING_NOTIFICATION: `Your monthly commission cap is {commissionCap}.{br}Your remaining cap is {remainingCap}.{br}Your spending is {estimatedCapSpendingRate} of estimated spending.{br}Your average daily spending is {avgDailyCommission}.`,

    RP_DAILY_TREND: 'Daily trend',
    RP_MONTHLY_TREND: 'Monthly trend',
    RP_LAST_UPDATE: 'Last update',
    RP_IMPRESSION_COUNT: 'Impression count',
    RP_CLICK_COUNT: 'Click count',
    RP_ORDER_COUNT: 'Order count',
    RP_ORDER_COUNT_2: 'Order',
    RP_ORDER_PAYMENT_AMT: 'Purchase amount',
    RP_CANCEL_COUNT: 'Cancel count',
    RP_CANCEL_AMT: 'Cancel amount',
    RP_REFERRAL_COUNT: 'Referral count',
    RP_COMMISSION: 'Commission',
    RP_COMMISSION_2: 'Commission',
    RP_FINAL_COMMISSION: 'Final Commission',
    RP_CTR: 'CTR',
    RP_CONVERSION: 'Conversion',
    RP_E_CPM: 'eCPM',
    RP_GMV: 'Total amount',
    RP_QUANTITY: 'Quantity',
    RP_ACTUAL_PAYMENT_AMT: 'Actual payment amount',
    RP_ACTUAL_PAYMENT_AMT_2: 'Actual payment amount',
    RP_ACCUMULATED_PAYOUT_AMT: 'Accumlated payout amount',
    RP_COMMISSION_CAP: 'Commission cap',
    RP_MANUAL_BONUS: 'Manual bonus',
    RP_REVENUE_UNIT: 'Unit: ₩10,000',
    RP_ALL_TIME_ACCUMULATED_PAYOUT_AMT: 'All time accumulated payout amount',
    RP_EARNING_MONTH: 'Earnings for {month}',
    RP_EARNING_SUMMARY_TIPS: '* Amount can change due to cancellation by next settlement day',
    RP_VIEW_FULL_REPORT: 'View full report',
    RP_PREVIOUS_30_DAYS: 'Previous 30 days',
    RP_NEXT_30_DAYS: 'Next 30 days',
    RP_PRODUCT: 'Product',
    RP_LINK_TYPE: 'Link Module Group(linkType)',
    RP_LINK_MODULE: 'Link Module',
    RP_TRACE_ID: 'Trace ID',
    RP_SUB_ID: 'Sub ID',
    RP_PLATFORM: 'Platform',
    RP_PAGE_TYPE: 'Page Type',
    RP_PAGE_KEY: 'Page Key',
    RP_DOWNLOAD_POPUP_CONTENT: 'The report is being generated(Job id is {jobId}), would you like to go to status page now?<br />(you can visit the status page from Home > Report > Download Report)',
    RP_REFERRAL_PERIOD: 'period',

    REPORT_REFERRAL_SUB_TITLE: 'people who referred me as referrer',

    SETTLEMENT_SUB_TITLE: 'Updated on the 25th of the month following the performance date',
    SETTLEMENT_COL_MONTH: 'Settlement month',
    SETTLEMENT_COL_PURCHASE: 'Orders',
    SETTLEMENT_COL_PURCHASE_AMOUNT: 'Purchase amount',
    SETTLEMENT_COL_CANCEL: 'Cancellations',
    SETTLEMENT_COL_CANCEL_AMOUNT: 'Cancellation amount',
    SETTLEMENT_COL_TOTAL_AMOUNT: 'Total amount',
    SETTLEMENT_COL_REVENUE: 'Revenue',
    SETTLEMENT_FINAL_REVENUE: 'Final Revenue',
    SETTLEMENT_COL_SETTLEMENT_STATUS: 'Settlement status',
    SETTLEMENT_STATUS_TIPS: 'Click the text to see detail reason.',
    SETTLEMENT_COL_WITHHOLDING_TAX: 'Withholding tax',
    SETTLEMENT_COL_VAT: 'Vat',
    SETTLEMENT_COL_REAL_PAYMENT_AMT: 'Real payment amt',
    SETTLEMENT_COL_PAYMENT_DATE: 'Payment date',
    SETTLEMENT_COL_HAS_PAID: 'Paid (Y/N)',
    SETTLEMENT_VIEW_DETAILS: 'Details',

    SETTLEMENT_DETAILS_COL_MONTH: 'Settlement month',
    SETTLEMENT_DETAILS_COL_BLOCKED: 'Blocked',
    SETTLEMENT_DETAILS_COL_TYPE: 'Type',
    SETTLEMENT_DETAILS_COL_TRACKING_CODE: 'Tracking code',
    SETTLEMENT_DETAILS_COL_SUBID: 'Sub Id',
    SETTLEMENT_DETAILS_COL_ORDER: 'Orders',
    SETTLEMENT_DETAILS_COL_CANCEL: 'Cancellations',
    SETTLEMENT_DETAILS_COL_PURCHASE_AMOUNT: 'Purchase amount',
    SETTLEMENT_DETAILS_COL_CANCEL_AMOUNT: 'Cancellations amount',
    SETTLEMENT_DETAILS_COL_COMMISSION: 'Revenue',
    SETTLEMENT_DETAILS_COL_PAYOUT_MONTH: 'Payout month',
    SETTLEMENT_DETAILS_COL_PAYOUT_DATE: 'Payout date',

    COMMISSION_TYPE_COMMISSION: 'Commission',
    COMMISSION_TYPE_BONUS_REFERRER: 'Bonus referrer',
    COMMISSION_TYPE_BONUS_REFERRAL: 'Bonus referral',
    COMMISSION_TYPE_BONUS_GMV_GROWTH: 'Bonus GMV growth',
    COMMISSION_TYPE_MANUAL: 'Manual',
    COMMISSION_TYPE_ADMIN_MANUAL_BONUS: 'Admin manual bonus',
    COMMISSION_TYPE_COMMISSION_CAP_DEDUCTION: 'Deduction from commission cap',

    PAYOUT_STATUS_CARRY_OVER: 'Carry Over',

    TAX_INVOICE_SUB_TITLE: 'Check actual payment date and history',
    TAX_INVOICE_COL_MONTH: 'Settlement month',
    TAX_INVOICE_COL_BILL_NUMBER: 'Bill No.',
    TAX_INVOICE_COL_AFFILIATE_CODE: 'Tracking code',
    TAX_INVOICE_COL_ISSUE_DATE: 'Issue date',
    TAX_INVOICE_COL_INVOICE_SEQ: 'Invoice Sequence',
    TAX_INVOICE_COL_VIEW_INVOICE: 'View invoice',
    TAX_INVOICE_COL_PAYMENT_DATE: 'Payment date',
    TAX_INVOICE_COL_TOTAL_AMOUNT: 'Total amount',
    TAX_INVOICE_COL_VAT: 'VAT',
    TAX_INVOICE_COL_AMOUNT_TO_PAY: 'Amount to pay',
    TAX_INVOICE_COL_STATUS: 'Invoice Status',
    TAX_INVOICE_PENDING_INVOICE_MSG: 'There is an unapproved tax bill. You must be approved within the due date to be paid.',

    UNSUBSCRIBE_TITLE: 'Unsubscribe',
    UNSUBSCRIBE_NOTICE: 'Notice',
    UNSUBSCRIBE_NOTICE_MSG_1: 'After applying for withdrawal, you cannot use Partners functions except for settlement related functions.',
    UNSUBSCRIBE_NOTICE_MSG_2: 'If there is residual income as of the date of withdrawal, the withdrawal process is completed after payment is completed. <br/> (If you are a recommender or a recommended subscriber, the remaining income is checked as of the next settlement date.)',
    UNSUBSCRIBE_NOTICE_MSG_3: 'Profits generated after the withdrawal application date cannot be paid.',
    UNSUBSCRIBE_NOTICE_MSG_4: 'Once the withdrawal process is complete, it is impossible to check the existing information.',
    UNSUBSCRIBE_NOTICE_MSG_5: 'You can re-register after the withdrawal process is completed.',
    UNSUBSCRIBE_NOTICE_MSG_6: 'The application for withdrawal cannot be reversed.',
    UNSUBSCRIBE_REASON: 'Reason',
    UNSUBSCRIBE_REASON_PLACEHOLDER: '(Select other) Please enter your reason',
    UNSUBSCRIBE_REASON_DUPLICATE_ACCOUNT: 'I have a duplicated account',
    UNSUBSCRIBE_REASON_EARNING_NOT_GOOD: 'Unsatisfied with the earnings',
    UNSUBSCRIBE_REASON_STOPPED_BUSINESS: 'I stopped the business',
    UNSUBSCRIBE_REASON_OTHERS: 'Others',
    UNSUBSCRIBE_PAYOUT: 'Payout',
    UNSUBSCRIBE_PAYOUT_MESSAGE: 'Do you continue to get unpaid commission?',
    UNSUBSCRIBE_USER_MENU: 'Unsubscribe',
    UNSUBSCRIBE_CAN_NOT_PAYOUT_REASON_1: 'If you deactivate before final approval, you will not be able to receive any remaining proceeds.',
    UNSUBSCRIBE_CAN_NOT_PAYOUT_REASON_2: 'Profits from unexplained misconduct cannot be paid when deactivate.',
    UNSUBSCRIBE_CAN_NOT_PAYOUT_REASON_3: 'GMV generated, but RRN/Bank Account is not provided. You have to give up the commission. ',
    UNSUBSCRIBE_GIVE_UP_PAYOUT_TIPS: 'For individual and other industries, the commission will not be paid until the withdrawal is completed by the end of each month. If you withdraw after the reference date, some commission may be paid even if you give up. <br> (Example: As of June, only if the withdrawal is completed by June 30th, the commission from May 1st to May 31st (settlement on June 25th) are not paid on the settlement date (July 15th), If you withdraw after June 30th, the commission on July 15th will be paid.)',
    DEACTIVATE: 'Deactivation',

    WIDGET_LIST_SUB_TITLE: 'The best product of the selected category is provided as carousel.',
    WIDGET_LIST_CREATE: 'CREATE',
    WIDGET_LIST_COL_NAME: 'Name',
    WIDGET_LIST_COL_TYPE: 'Type',
    WIDGET_LIST_COL_DATASET_NAME: 'Dataset Name',
    CREATED_DATE: 'Created Date',
    LAST_MODIFIED: 'Last Modified',
    DEL_CONFIRM_TITLE: 'Confirm​?',
    DEL_CONFIRM_DESCRIPTION: 'Are you sure you want to delete "{name}"?​',

    WIDGET_LIST_COL_ACTIONS: 'Actions',
    WIDGET_LIST_DEL_CONFIRM_TITLE: 'Confirm',
    WIDGET_LIST_DEL_CONFIRM_DESCRIPTION: 'Do you really want to delete "{name}"?',

    WIDGET_INST_TITLE: 'Create dynamic banner',
    WIDGET_INST_PREVIEW: 'Preview',
    WIDGET_INST_SETTINGS: 'Widget settings',
    WIDGET_INST_NAME: 'Name',
    WIDGET_INST_DATA_TYPE: 'Data type',
    WIDGET_INST_DATA_SET: 'Data set',
    WIDGET_INST_PARAMETERS: 'Parameters',
    WIDGET_INST_PARAM_WIDTH: 'Width',
    WIDGET_INST_PARAM_HEIGHT: 'Height',
    WIDGET_INST_PARAM_BORDER: 'Border',
    WIDGET_INST_WITH_BORDER: 'With borderline',
    WIDGET_INST_WITHOUT_BORDER: 'Without borderline',
    WIDGET_INST_SIZE_VALIDATION_MSG: 'Value should between 50~2000 pixels',
    WIDGET_INST_CLOSE: 'Close',
    WIDGET_INST_GENERATE: 'Generate Code',
    WIDGET_INST_CLOSE_CONFIRM_TITLE: 'Do you want to close?',
    WIDGET_INST_CLOSE_CONFIRM_DESCRIPTION: 'Click Confirm button to close this page.',
    WIDGET_INST_CODE: 'Code',
    WIDGET_INST_DATASET_MANAGEMENT: 'Banner Product Selection',
    WIDGET_INST_DATASET_NAME: 'Name',
    WIDGET_INST_DATASET_DELETE_CONFIRM_TITLE: 'Confirm',
    WIDGET_INST_DATASET_DELETE_CONFIRM: 'Do you really want to delete [{name}]?',

    OPEN_API_BANNER_TIP: 'Coupang Partners API provides various services of Coupang Partners in an interface format to help your profitable activities. This provides Partners with features and performance information including Coupang product information that can be applied to your web site or application.',
    OPEN_API_BANNER_HELP_LINK: 'For more information on the Open API, check out the link.',
    OPEN_API_INACTIVE_TITLE: 'API Credentials',
    OPEN_API_INACTIVE_MSG: "Create the information for using the Open API by clicking the 'Generate' button below.",
    OPEN_API_ACTIVE_TITLE: 'API Credentials',
    OPEN_API_ACTIVE_MSG: 'The Access Key and Secret Key below are unique text strings that demonstrate that you are an authenticated user, which you can use to call the Partners API.<br/>Please be careful <span class="color-danger ft-bold-500">not</span> to expose Access Key and Secret Key due to security problems.',
    OPEN_API_BLOCKED_TITLE: 'API Key blocked',
    OPEN_API_BLOCKED_MSG: "API Key has been blocked. Please contact admin.",
    OPEN_API_DELETED_TITLE: 'API Key deleted',
    OPEN_API_DELETED_MSG: "API Key has been deleted. Please contact admin.",
    OPEN_API_BTN_GENERATE: 'Generate',

    CHROME_EXT_INSTALL_CHROME: 'Install Chrome',
    CHROME_EXT_INSTALL_EXENTION: 'Install Affiliate Extension',
    CHROME_EXT_DOWNLOAD_CHROME: 'Download Chrome',
    CHROME_EXT_DOWNLOAD_EXTENSION: 'Download Affiliate Extension',

    REACTIVATE_TITLE: 'Do you want to proceed with re-join?',
    REACTIVATE_CHECK_NOTICE: 'Please check the following before proceeding with re-join.',
    REACTIVATE_NOTICE1: 'Re-join will assign a new AF ID',
    REACTIVATE_NOTICE2: 'You will be transferred to the new created account, but you will not be able to see the information from your account after re-join.',
    REACTIVATE_NOTICE3: 'The Merchandise Link / Banner / Widget / API of your existing account will not be linked to your new account, you must create new ones after re-activation.',

    SUB_ID_TITLE: 'Sub Id Management',
    SUB_ID_SUB_TITLE: 'You can manage subIds here. Please check {help} for details. ',
    SUB_ID: 'Sub Id',
    SUB_ID_INPUT_PLACEHOLDER: '8-20 letters or numbers',
    SUB_ID_EMPTY: 'No data',
    SUB_ID_DELETE_MODAL_TITLE: 'Are you confirm to delete?',
    SUB_ID_DELETE_MODAL_CONTENT: '{subId}Are you sure you want to delete? {br} Settlement and payment for deleted Sub IDs will be not available.',
    SUB_ID_CREATE_MODAL_TITLE: 'Create Sub-id',
    CREATE_METHOD: 'Create Method',

    SUB_ID_INVALID: 'Sub IDs can be from 8 to 20 characters and English capitalization only.',
    SUB_ID_IS_EXISTED: 'Existed Sub ID',
    SUB_ID_MAX_COUNT: 'Max {count} Sub IDs',

    SUB_ID_DEFAULT: 'default(all)',

    NOTIFICATION_TITLE: 'Notifications',
    NOTIFICATION_EMPTY: 'No notification',
    NOTIFICATION_VIEW_ALL: 'View all',
    NOTIFICATION_MARK_ALL_AS_READ: 'Mark all as read',
    NOTIFICATION_MARK_AS_READ: 'Mark as read',
    NOTIFICATION_ANY_TYPE: 'Any type',
    NOTIFICATION_TYPE_GENERAL: 'General',
    NOTIFICATION_TYPE_ALERT: 'Alert',
    NOTIFICATION_TYPE_PROMOTION: 'Promotion',

    DOWNLOAD_REPORT_COL_ID: 'ID',
    DOWNLOAD_REPORT_COL_TYPE: 'Type',
    DOWNLOAD_REPORT_COL_PERIOD: 'Period',
    DOWNLOAD_REPORT_COL_SUBIDS: 'Channels',
    DOWNLOAD_REPORT_COL_STATUS: 'Status',
    DOWNLOAD_REPORT_COL_SIZE: 'Size',
    DOWNLOAD_REPORT_COL_CREATED_DATE: 'Created date',
    DOWNLOAD_REPORT_JOB_TYPE_DAILY_TREND: 'Daily Trend',
    DOWNLOAD_REPORT_JOB_TYPE_AFFILIATE_DAILY_TREND: 'Daily Trend',
    DOWNLOAD_REPORT_JOB_TYPE_AFFILIATE_DYNAMIC_BANNER_DAILY_TREND: 'Dynamic Banner Daily Trend',
    DOWNLOAD_REPORT_STATUS_INIT: 'Init',
    DOWNLOAD_REPORT_STATUS_RUNNING: 'Running',
    DOWNLOAD_REPORT_STATUS_SUCCEED: 'Succeeded',
    DOWNLOAD_REPORT_STATUS_FAILED: 'Failed',
    DOWNLOAD_REPORT_JOB_DELETE_CONFIRM: 'Do you really want to delete job [{id}]?',

    USER_STATUS_TIP_TEXT_APPROVED: 'Final approved',
    USER_STATUS_TIP_TEXT_REJECTED: 'Rejected',
    USER_STATUS_TIP_TEXT_PENDING: 'In progress',
    USER_STATUS_TIP_TEXT_DEFAULT: 'Activated',

    ANNOUNCEMENT_CATEGORY: 'Category',

    CURRENCY_CODE_WON: 'WON',
    ENGLISH_AND_NUMBERS_ONLY: 'English and Numbers Only',
    VIEW_DETAIL: 'View Detail',
    VIEW_MORE: 'View More',
    CLOSE: 'Close',
    PREVIOUS: 'Previous',
    NEXT: 'Next',
    WIZARD_PREVIOUS: 'Previous',
    WIZARD_NEXT: 'Next',
    DONE: 'Done',
    ADD: 'Add',
    ADD_URL: 'Add',
    OK: 'OK',
    CHANGE: 'Change',
    CANCEL: 'Cancel',
    CONFIRM: 'Confirm',
    CONTINUE: 'Continue',
    SAVE: 'Save',
    APPROVE: 'Approve',
    REAPPLY: 'Re-apply',
    VERIFY: 'Verify',
    LOGIN: 'Login',
    SIGNUP: 'Signup',
    SIGNEDUP: 'Signup',
    LOGOUT: 'Logout',
    MULTIPLE_SELECTION: 'Multiple selection',
    ALL: 'All',
    SHOPPING: 'Shopping',
    COPIED: 'Copied!',
    COPIED_DISCLAIMER: `Copy done! Don't forget to put the phrase in the post!`,
    SEARCH_RESULTS: 'Search results',
    ERROR: 'Error',
    SESSION_TIMEOUT_RELOGIN: 'Session timeout, please re-login',
    ARE_YOU_SURE: 'Are you sure?',
    NOTICE: 'Notice',
    COPY: 'Copy',
    ID: 'ID',
    LOOKUP: 'View',
    VIEW_DETAILS: 'Details',
    YES: 'Yes',
    NO: 'No',
    ACTIONS: 'Actions',
    DELETE: 'Delete',
    DELETED: 'Deleted',
    VIEW_MORE_DIMENSION: 'View more list',
    VISIT_PAGE: 'Visit page',
    HELP: 'help',

    PAY_STATUS: 'Payout Status',

    COUPANG_RANKING: 'Coupang Ranking',
    COUPANG_RANKING_LOW_PRICE: 'Low price',
    COUPANG_RANKING_HIGH_PRICE: 'High price',
    COUPANG_RANKING_SALES_ORDER: 'Sales order',
    COUPANG_RANKING_THE_LATEST: 'The lates',

    VAL_MSG_THIS_FIELD_IS_REQUIRED: 'This field is required',
    VAL_MSG_FIELD_REQUIRED: '{name} is required',
    VAL_MSG_ENTER_VALID_FIELD: 'Please enter valid {name}',
    VAL_MSG_INVALID_FIELD: 'Invalid {name}',
    VAL_MSG_FIELD_INVALID: '{name} is invalid',

    MSG_INVALID_IMAGE_FILE: 'Invalid image file',
    MSG_START_DATE_IS_REQUIRED: 'Please select a start date.',
    MSG_END_DATE_IS_REQUIRED: 'Please select an end date.',
    MSG_OPEN_API_KEYS_APPLIED_FAILED: 'Failed to apply Open API credentials.',
    MSG_OPEN_API_KEYS_ALREADY_APPLIED: 'You have applied the credentials.',
    MSG_FAILED_TO_LOAD_DATA: 'Failed to load data',

    PAYEE_NAME_MUST_MATCH_ACCOUNT_HOLDER: 'Payee name must match with account holder',
    BANK_ACCOUNT_LENGTH_AT_LEAST: 'Bank account at least {n} digits',

    CHANGE_THE_UPLOADED_FILE: 'Change the file',
    CHANGE_THE_UPLOADED_DOC_CONFIRMATION: 'You already uploaded a document. Do you want to change it?',
    CHOOSE_A_IMAGE_FILE: 'Please select an image file.',
    FILE_SIZE_IS_TOO_LARGE: 'File size must be less than 5MB',
    UPLOAD_COMPLETED: 'Upload completed.',

    DO_YOU_WANT_TO_REGISTER_THE_DOCUMENTS: 'Do you want to register the documents?',

    CONSENT_WHEN_YOUR_CHANGE_SITES: 'Please confirm this',

    AUTH_FAILED: 'Authentication failed',
    PASSWORD_INCORRECT: 'Password is incorrect',

    ONLY_PROVIDE_REPORTS_FOR_UP_TO_N_DAYS: 'We only provide reports for up to {n} days.',

    DOWNLOAD: 'Download',
    START_DATE: 'Start date',
    END_DATE: 'End date',
    DATE_RANGE: 'Date range',

    YESTERDAY: 'Yesterday',
    ONE_WEEK: 'One week',
    '30_DAYS': '30 days',
    CUSTOMIZED: 'Customized',

    NO_DATA: 'no data',
    N_SELECTED: '{n} selected',
    METRIC: 'Metric',

    VERSION: 'Version',
    SELECT: 'Select',
    DESCRIPTION: 'Description',
    TITLE: 'Name',
    AFFILIATE_FEE_NOTICE: `
<div style="color:#e56a2c">
When writing a post, be sure to include the following text.<br />
“This posting is part of Coupang Partners’ activities, and a certain amount of commission is provided accordingly.”<br /><br />
</div>
The activities of Coupang Partners must be disclosed in accordance with the review guidelines of the Fair Trade Commission about the economic interests of Partners members who are recommendations and guarantors and the Company.<br />
For more information, please refer to “Help>Frequently Asked Questions>Usage Inquiry>Do I need to use a paid text when inserting advertisements?” (insert link).`,

    
    SEND: 'SEND',
    JS_TAG: 'js tag',
    IFRAME_TAG: 'iframe tag',
    HTML_TAG: 'html tag',

    DATA_RETENTION_FOR_180_DAYS: 'Data is kept for 180 days',
    
    PLEASE_AGREE_ALL: 'Please agree to all terms',

    DOWNLOAD_PDF: 'Download PDF',

    VIEW_DOCUMENT: '전문 보기',

    NAME: 'Name',
    BIRTHDAY: 'Birthday',
    NAME_CHANGE: 'Name change',
    NAME_SYNC_HELP: 'If you have changed your name, you must 1. complete Coupang verification and 2. complete NICE verification in order to update the name in Coupang Partners',
    NAME_SYNC_SUCCESS: 'Name has been changed',
    SENDER: 'Sender',
    TIME: 'Time',
    RESULTS: 'Results',
    PASSBACK: 'Passback',

    IMPORTANT: 'Important',

}
